<template>
	<div class="wrap">
		<div class="flex justify-end">
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button @click="toEdit()">新增</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" class="width-100p" v-loading="loading" max-height="9999">
			<el-table-column type="index" width="50"></el-table-column>
			<el-table-column prop="name" label="角色名称"></el-table-column>
			<el-table-column fixed="right" label="操作" width="180">
				<template slot-scope="scope">
					<el-button size="mini" @click="toEdit(scope.row)">编辑</el-button>
					<el-button size="mini" @click="del(scope.row)" v-if="scope.row.name!='超级管理员'">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				tableData: []
			};
		},
		mounted() {
			this.loadData();
		},
		methods: {
			toEdit(role) {
				if (role) {
					// 编辑
					this.$router.push(`/systemRole/edit/${role.role_id}`);
				} else {
					// 新增
					this.$router.push("/systemRole/edit");
				}
			},
			loadData() {
				this.loading = true;
				this.$api.system.getRoles().then(res => {
					this.tableData = res.data
					this.loading = false;
				})
			},
			del(role) {
				this.$confirm(
						"删除该角色后，拥有该角色的用户将失去角色权限, 是否继续?", {
							type: "warning"
						}
					)
					.then(() => {
						const loading = this.$loading();
						this.$api.system.delRole({
							id: role.role_id
						}).then(res => {
							loading.close();
							if (res.code == 200) {
								this.loadData();
								this.$message.success("删除成功");
							} else {
								this.$message.error(res.msg);
							}
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除"
						});
					});
			}
		}
	};
</script>
<style scoped>
	.el-table>>>.el-table__fixed-right::before {
		height: 0;
	}

	.el-table::before {
		height: 0;
	}
</style>
